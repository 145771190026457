<template>
  <div class="containBox">
    <!-- 上面的bannar -->
    <div class="bannerBox">
      <img src="@/assets/images/product/banner.png" alt="" />
    </div>
    <!-- <div class="productBox">{{ $t("product.productClassification") }}</div>
    <div class="product_line">
      <img src="@/assets/images/product/line.png" alt="" />
    </div> -->
    <Lines titleText="product.productClassification"></Lines>
    <!-- 一级分类 -->
    <div class="classBox">
      <div class="classTOp">
        <div
          class="classTOpItem"
          v-for="(item, index) in productClass"
          :key="index"
          :class="{ active: index == classActive }"
          @click="btnClass(index, item)"
        >
          {{ item }}
        </div>
        <div class="classTOpright" @click="btnAllList()">
          {{ $t("product.List") }}
        </div>
      </div>
      <div class="classLit">
        <div
          class="classLitItem"
          v-for="item in get_cate1List"
          :key="item.id"
          @click="GolistBtn(item)"
        >
          <div class="classLitItemImg">
            <img class="auto-img" :src="item.img ? item.img : imgsrc" alt="" />
          </div>
          <div class="classLitItemText">
            {{ language == "zh" ? item.title : item.english || "--" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_cate1,
  get_material,
  get_special_cate,
  get_cate2,
  get_style,
} from "@/utils/http.js";
import imgsrc from "@/assets/images/product/noImg.png";
import Lines from "@/components/line.vue";
import { mapState } from "vuex";
export default {
  name: "CompanynetworkPIndex",
  components: {
    Lines,
  },
  data() {
    return {
      productClass: this.$t("productClass"),
      classActive: 0,
      get_cate1List: [], // 品类数据
      get_materialList: [], //材质数据
      get_special_cateList: [], //细分分类
      get_cate2List: [], //适用途径
      get_styleList: [], // 风格
      goods_list_data: [], // 每一个产品
      imgsrc: imgsrc,
      giveId: "",
      giveType: "品类",
    };
  },
  beforeUpdate() {
    this.productClass = this.$t("productClass");
  },
  created() {
    this.get_cate1();
  },
  computed: {
    ...mapState(["language"]),
  },
  mounted() {},
  methods: {
    // 获取品类
    async get_cate1() {
      const res = await get_cate1();
      console.log(res);
      this.get_cate1List = res.list;
    },
    // 获取材质
    async get_material() {
      const res = await get_material();
      // this.get_materialList = res.list;
      this.get_cate1List = res.list;
    },
    // 分类功能
    async get_special_cate() {
      console.log(123456);
      const res = await get_special_cate();
      // this.get_special_cateList = res.list;
      this.get_cate1List = res.list;
    },
    // 适用途径
    async get_cate2() {
      const res = await get_cate2();
      // this.get_special_cateList = res.list;
      this.get_cate1List = res.list;
    },
    // 风格
    async get_style() {
      const res = await get_style();
      // this.get_special_cateList = res.list;
      this.get_cate1List = res.list;
    },

    // 点击品类 材质等按钮
    // btnClass(index, item) {
    //   console.log(item);
    //   if (index == 0 && this.classActive != index) {
    //     this.get_cate1();
    //     this.giveType = "品类";
    //   } else if (index == 1 && this.classActive != index) {
    //     this.get_material();
    //     this.giveType = "材质";
    //   } else if (index == 2 && this.classActive != index) {
    //     this.get_special_cate();
    //     this.giveType = "细分分类";
    //   } else if (index == 3 && this.classActive != index) {
    //     this.get_cate2();
    //     this.giveType = "适用用途";
    //   } else if (index == 4 && this.classActive != index) {
    //     this.get_style();
    //     this.giveType = "风格";
    //   }
    //   this.classActive = index;
    // },
    btnClass(index, item) {
      console.log(item);
      if (index == 0 && this.classActive != index) {
        this.get_cate1();
        this.giveType = "品类";
      } else if (index == 1 && this.classActive != index) {
        this.get_material();
        this.giveType = "材质";
      } else if (index == 2 && this.classActive != index) {
        this.get_style();
        this.giveType = "风格";
      }
      this.classActive = index;
    },
    //点击全部列表
    btnAllList(item) {
      this.$router.push({
        name: "productList",
        // query: {
        //   id: this.giveId,
        // },
      });
    },
    //点击分类进入列表页面
    GolistBtn(item) {
      let item2 = { type: this.giveType, ...item };

      // item.type = "";
      // item1["type"] = this.giveType;
      // item1.type = this.giveType;
      let item1 = JSON.stringify(item2);
      let type = this.giveType;

      this.$router.push({
        name: "productList",
        query: {
          item: item1,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.containBox {
  .bannerBox {
    height: 436px;
    min-width: 1410px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .productBox {
    height: 45px;
    font-size: 46px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-top: -39px;
  }
  .product_line {
    margin-top: 23px;
    text-align: center;
  }
  .classBox {
    margin-top: 50px;
    .classTOp {
      position: relative;
      text-align: center;
      height: 42px;
      line-height: 42px;
      cursor: pointer;
      .classTOpItem {
        display: inline-block;
        // width: 102px;
        line-height: 42px;
        padding: 0 20px;
        text-align: center;
        height: 42px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        border: 1px solid #cccccc;
        border-radius: 21px;
        margin: 0 15px;
      }
      .classTOpright {
        position: absolute;
        top: 0px;
        right: 0px;
        margin-right: 225px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
      .active {
        background: #2e82ff;
        color: #fff;
      }
    }
    .classLit {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 54px 190px 0 225px;
      // background-color: pink;
      .classLitItem {
        margin-bottom: 50px;
        .classLitItemImg {
          border: 1px solid #ccc;
          margin-right: 28px;
          width: 220px;
          height: 220px;
          .auto-img {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
        }
        .classLitItemText {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
<template>
  <div class="container" v-if="!showrMore">
    <span
      class="radio"
      :class="{ active: isActive == item.id }"
      v-for="(item, index) in dataList"
      :key="index"
      @click.stop="selectItem(item)"
      >{{ language == "zh" ? item.title : item.english || "--" }}</span
    >
    <span class="resBtnItme" @click="restBtn">{{
      language == "zh" ? "重置" : "resetting"
    }}</span>
  </div>
  <div class="container" v-else>
    <span
      class="radio"
      v-for="(item, index) in dataList"
      :class="{ active: item.show }"
      :key="index"
      @click.stop="selectMoreItem(item)"
      >{{ language == "zh" ? item.title : item.english || "--" }}
    </span>
    <span class="resBtnItme" @click="restBtn">{{
      language == "zh" ? "重置" : "resetting"
    }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CompanynetworkPPopUp",
  props: {
    dataList: {
      type: Array,
    },
    dataId: {},
    showrMore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: "",
    };
  },
  computed: {
    ...mapState(["language"]),
  },

  created() {
    this.isActive = this.dataId;
    // this.language = localStorage.getItem("lang");
  },
  beforeUpdate() {
    // this.language = localStorage.getItem("lang");
    // console.log(this.language);
  },
  mounted() {},
  methods: {
    // 点击每一项获取数据id
    selectItem(item) {
      if (this.isActive == item.id) {
        this.isActive = "";
        this.$emit("publicEvents");
      } else {
        this.isActive = item.id;
        this.$emit("publicEvents", item);
      }
      // 调用父组件请求列表的方法
    },
    // 点击重置按钮
    restBtn() {
      this.isActive = "";
      this.$emit("restEvents");
    },
    selectMoreItem(item) {
      this.$emit("publicEvents", item);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  cursor: pointer;
  margin-top: 35px;
  margin-bottom: 35px;
  padding-left: 115px;
  width: 1920px;
  .radio {
    display: inline-block;
    color: #333333;
    margin-right: 28px;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .active {
    color: #2e82ff;
  }
  .resBtnItme {
    cursor: pointer;
    margin-left: 221px;
    border-bottom: 2px solid #000;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1d1d1d;
  }
}
</style>
<template>
  <div id="container"></div>
</template>

<script>
export default {
  name: "Demo1Map",

  data() {
    return {
      lat: "",
      lng: "",
    };
  },
  mounted() {
    let that = this;
    var map = new AMap.Map("container", {
      resizeEnable: true,
      zoom: 15,
      center: [120.003462, 30.280261],
      lang: "zh_en", //可选值：en，zh_en, zh_cn
    });
    AMap.plugin("AMap.ToolBar", function () {
      //异步加载插件
      var toolbar = new AMap.ToolBar();
      map.addControl(toolbar);
    });
    // 创建一个 Marker 实例：
    var marker = new AMap.Marker({
      position: new AMap.LngLat(120.003462, 30.280261), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: "杭州",
    });
    // 将创建的点标记添加到已有的地图实例：
    marker.on("click", function (e) {
      console.log(that.lng, that.lat);
      if (that.lng) {
        window.open(
          `https://uri.amap.com/navigation?from=${that.lng},${that.lat},我的位置&to=120.003462,30.280261,欧美金融城t2英国中心&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0`,
          "_blank"
        );
      } else {
        window.open(
          `https://uri.amap.com/navigation?to=120.003462,30.280261,欧美金融城t2英国中心&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0`,
          "_blank"
        );
      }
    });
    map.add(marker);
    AMap.plugin("AMap.Geolocation", function () {
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量
        offset: [10, 20],
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,
        //  定位按钮的排放位置,  RB表示右下
        position: "RB",
      });
      geolocation.getCurrentPosition(function (status, result) {
        if (status == "complete") {
          onComplete(result);
        } else {
          onError(result);
        }
      });
      function onComplete(data) {
        // data是具体的定位信
        that.lat = data.position.lat;
        that.lng = data.position.lng;
      }
      function onError(data) {
        console.log(data);
        // 定位出错
      }
    });
  },

  methods: {},
};
</script>

<style  scoped>
.box {
  width: 800px;
  height: 800px;
}
#container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div data-aos="fade-down" data-aos-offset="200" :data-aos-once="true">
    <div class="productBox">{{ $t(titleText) }}</div>
    <div class="product_line">
      <img src="@/assets/images/product/line.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPLine",
  props: {
    titleText: {
      type: String,
    },
  },
  data() {
    return {};
  },
  beforeUpdate() {
    console.log(89898989);
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.productBox {
  height: 45px;
  font-size: 46px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-top: -39px;
}
.product_line {
  margin-top: 23px;
  text-align: center;
}
</style>
// 判断是哪个请求地址

// let address = "development"; // 测试
let address = "production "; // 线上
let baseURL = "";
if (address == "development") {
  baseURL = "/";
} else if (address == "production") {
  baseURL = "https://m.td-button.com";
}
export default baseURL;

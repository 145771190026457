<template>
  <div class="company">
    <div class="topImg"></div>
    <div class="companyText">
      <Lines titleText="company.company_introduction"></Lines>
      <div
        class="companyTextTop"
        data-aos="fade-down"
        data-aos-offset="200"
        :data-aos-once="true"
      >
        {{ $t("company.company_introduce") }}
      </div>
      <div class="companyImg">
        <div
          class="companyImgLeft companyImgBox"
          data-aos="slide-right"
          data-aos-offset="200"
          :data-aos-once="true"
        >
          <img
            class="componyImg"
            style="width: 100%; height: 100%"
            :src="big5"
            alt=""
          />
        </div>
        <div
          class="companyImgCenter"
          data-aos="slide-left"
          data-aos-offset="200"
          :data-aos-once="true"
        >
          <div class="companyImgBox">
            <img
              class="componyImg"
              style="width: 100%; height: 100%"
              :src="big1"
              alt=""
            />
          </div>
          <div class="companyImgBox">
            <img
              class="componyImg"
              style="width: 100%; height: 100%"
              :src="big2"
              alt=""
            />
          </div>
        </div>
        <div
          class="companyImgRight"
          data-aos="slide-left"
          data-aos-offset="200"
          :data-aos-once="true"
        >
          <div style="overflow: hidden">
            <img
              class="componyImg"
              style="width: 100%; height: 100%"
              :src="big3"
              alt=""
            />
          </div>
          <div style="overflow: hidden">
            <img
              class="componyImg"
              style="width: 100%; height: 100%"
              :src="big4"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- <div
        class="topShow"
        data-aos="slide-right"
        data-aos-offset="200"
        :data-aos-once="true"
      >
        <LeftRight :title1="title1" :title2="title2"> </LeftRight>
      </div> -->
      <!-- 引入组件展示这种组 -->
      <!-- <div data-aos="slide-right" data-aos-offset="200" :data-aos-once="true">
        <LeftRight
          :toleft="true"
          :title1="titleLeft1"
          :title2="titleLeft2"
        ></LeftRight>
      </div> -->
      <threeItem
        :title="$t('company.glories_of_company')"
        :rongyu="rongyu"
      ></threeItem>
      <threeItem
        :title="$t('company.enterprise_qualification')"
        :zhizhiimg="zhizhiimg"
      ></threeItem>
    </div>
  </div>
</template>

<script>
import Lines from "@/components/line.vue";
import LeftRight from "@/components/leftRight.vue";
import threeItem from "./components/threeItem.vue";
import skrollr from "skrollr";
import zizhi1 from "@/assets/images/company/zizhi1.jpeg";
import zizhi2 from "@/assets/images/company/zizhi2.jpeg";
import zizhi3 from "@/assets/images/company/zizhi3.jpeg";
import zizhi4 from "@/assets/images/company/zizhi4.jpeg";
import zizhi5 from "@/assets/images/company/zizhi5.jpeg";
import zizhi6 from "@/assets/images/company/zizhi6.jpg";
import zizhi7 from "@/assets/images/company/zizhi7.jpg";
import zizhi8 from "@/assets/images/company/zizhi8.jpg";
import zizhi9 from "@/assets/images/company/zizhi9.jpg";
import rong1 from "@/assets/images/company/rong1.jpeg";
import rong2 from "@/assets/images/company/rong2.jpeg";
import rong3 from "@/assets/images/company/rong3.jpeg";
import big1 from "@/assets/images/company/big1.jpeg";
import big2 from "@/assets/images/company/big2.jpeg";
import big3 from "@/assets/images/company/big3.jpeg";
import big4 from "@/assets/images/company/big4.jpeg";
import big5 from "@/assets/images/company/big5.jpg";
export default {
  name: "CompanynetworkPCompany",
  components: {
    Lines,
    LeftRight,
    threeItem,
  },
  data() {
    return {
      zhizhiimg: [
        zizhi1,
        zizhi2,
        zizhi3,
        zizhi4,
        zizhi5,
        zizhi6,
        zizhi7,
        zizhi8,
        zizhi9,
      ],
      rongyu: [rong1, rong2, rong3],
      big1,
      big2,
      big3,
      big4,
      big5,
    };
  },
  beforeUpdate() {
    console.log("公司界面");
  },
  created() {
    // this.refes();
  },
  beforeDestroy() {
    let instance = skrollr.get();
    instance.destroy();
  },
  mounted() {
    this.$nextTick(() => {
      this.skrollrobj = skrollr.init({
        forceHeight: false,
        smoothScrolling: true,
        smoothScrollingDuration: 1000,
        easing: {
          vibrate: function (p) {
            return Math.sin(p * 10 * Math.PI);
          },
        },
      });
    });
  },
  methods: {
    refes() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  overflow: hidden;
  border-top: 1px solid #ccc;
  .topImg {
    margin: 0 auto;
    margin-top: 53px;
    width: 1500px;
    height: 722px;
    background-color: #e6e6e6;
    background: url("~@/assets/images/company/company.png") no-repeat;
    background-size: 100% 100%;
  }
  .companyText {
    box-sizing: border-box;
    margin-top: 91px;
    margin-bottom: 430px;
    // height: 2474px;

    background: url(~@/assets/images/company/bg.png) no-repeat;
    background-size: 100% 100%;
    .companyTextTop {
      width: 979px;
      // height: 128px;
      line-height: 30px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      margin: 0 auto;
      margin-top: 58px;
      text-align: justify;
    }
    .companyImg {
      margin: 0 auto;
      // background-color: pink;
      display: flex;
      justify-content: space-between;
      margin-top: 73px;
      height: 500px;
      width: 1180px;
      .companyImgLeft {
        width: 380px;
        height: 500px;
        background-color: #e6e6e6;
      }
      .companyImgCenter {
        width: 380px;
        height: 500px;
        div {
          width: 380px;
          height: 240px;
          background-color: #e6e6e6;
        }
        div:first-child {
          margin-bottom: 20px;
        }
      }
      .companyImgRight {
        width: 380px;
        height: 500px;
        // opacity: 0;
        // background-color: #e6e6e6;
        div {
          width: 380px;
          height: 240px;
          background-color: #e6e6e6;
        }
        div:first-child {
          margin-bottom: 20px;
        }
      }
    }
    .topShow {
      padding-top: 50px;
    }
  }
}
.componyImg:hover {
  transform: scale(1.2);
  transition: all 0.5s ease;
}
.componyImg {
  transition: all 0.5s ease;
}
.companyImgBox {
  overflow: hidden;
}
.prel {
  position: relative;
}
.prelA {
  position: absolute;
}
</style>
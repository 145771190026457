<template>
  <div class="threeItem">
    <div class="title">{{ title }}</div>
    <div
      class="imgBox"
      v-if="title == '企业资质' || title == 'Enterprise qualification'"
    >
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in zhizhiimg" :key="index"
          ><div class="grid-content bg-purple">
            <div class="item">
              <img :src="item" alt="" />
            </div></div
        ></el-col>
      </el-row>
    </div>
    <div class="imgBox" v-else>
      <el-row :gutter="20">
        <el-col :span="8" v-for="(item, index) in rongyu" :key="index"
          ><div class="grid-content bg-purple">
            <div class="item1">
              <img :src="item" alt="" />
            </div></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPThreeItem",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    zhizhiimg: {
      type: Array,
      default: () => [],
    },
    rongyu: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.threeItem {
  width: 1180px;
  margin: 0 auto;
  margin-top: 132px;
  .title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }
  .imgBox {
    display: flex;
    justify-content: space-between;
    .item {
      overflow: hidden;
      height: 380px;
      width: 268px;
      background: #eaeaea;
      border: 1px solid #eaeaea;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
      }
      img:hover {
        transform: scale(1.2);
        transition: all 0.5s ease;
      }
    }
    .item1 {
      overflow: hidden;
      width: 380px;
      height: 268px;
      background: #eaeaea;
      border: 1px solid #eaeaea;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
      }
      img:hover {
        transform: scale(1.2);
        transition: all 0.5s ease;
      }
    }
  }
}
</style>
<template>
  <div class="supBox">
    <Img></Img>
    <div class="lines">
      <Lines titleText="superiority.enterprise_advantage"></Lines>
    </div>
    <div class="bottomImg">
      <div class="imgTop">
        <Youshi
          data-aos="fade-down"
          data-aos-offset="200"
          :data-aos-once="true"
          data-aos-delay="500"
          number="01"
          title="superiority.first"
          :imgSrc="youshi1"
        ></Youshi>
        <Youshi
          data-aos="fade-down"
          data-aos-offset="200"
          :data-aos-once="true"
          data-aos-delay="1000"
          number="02"
          title="superiority.second"
          :imgSrc="youshi2"
        ></Youshi>
      </div>
      <div class="imgBottom">
        <Youshi
          data-aos="fade-down"
          data-aos-offset="200"
          :data-aos-once="true"
          data-aos-delay="500"
          number="03"
          title="superiority.third"
          :imgSrc="youshi3"
        ></Youshi>
        <Youshi
          data-aos="fade-down"
          data-aos-offset="200"
          :data-aos-once="true"
          data-aos-delay="1000"
          number="04"
          title="superiority.fourth"
          :imgSrc="youshi4"
        ></Youshi>
      </div>
      <div class="lines">
        <Lines titleText="superiority.Our_service"></Lines>
      </div>
      <div class="serveBoxBig">
        <div class="top">
          <Serveitem
            :imgSrc="server1"
            title="superiority.Our_first"
          ></Serveitem>
          <Serveitem
            :imgSrc="server2"
            title="superiority.Our_second"
          ></Serveitem>
          <Serveitem
            :imgSrc="server3"
            title="superiority.Our_third"
          ></Serveitem>
        </div>
        <div class="bottom">
          <Serveitem
            :imgSrc="server4"
            :showChang="true"
            title="superiority.Our_fourth"
          ></Serveitem>
          <Serveitem
            :imgSrc="server5"
            :showChang="true"
            title="superiority.Our_fifth"
          ></Serveitem>
        </div>
        <div class="topleftImg">
          <img src="@/assets/images/superiority/circle.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgSrc from "@/assets/images/product/img.png";
import server1 from "@/assets/images/superiority/server1.png";
import server2 from "@/assets/images/superiority/server2.png";
import server3 from "@/assets/images/superiority/server3.png";
import server4 from "@/assets/images/superiority/server4.png";
import server5 from "@/assets/images/superiority/server5.png";
import Img from "./components/img.vue";
import Lines from "@/components/line";
import Youshi from "./components/youshi.vue";
import Serveitem from "./components/serveitem.vue";
import youshi1 from "@/assets/images/superiority/youshi1.png";
import youshi2 from "@/assets/images/superiority/youshi2.png";
import youshi3 from "@/assets/images/superiority/youshi3.png";
import youshi4 from "@/assets/images/superiority/youshi4.png";
export default {
  name: "CompanynetworkPSuperiority",
  components: {
    Img,
    Lines,
    Youshi,
    Serveitem,
  },
  data() {
    return {
      imgSrc: imgSrc,
      youshi1: youshi1,
      youshi2,
      youshi3,
      youshi4,
      server1,
      server2,
      server3,
      server4,
      server5,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supBox {
  .lines {
    margin-top: 92px;
  }
  .bottomImg {
    background: url(~@/assets/images/superiority/superiorbg.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 440px;
    .imgTop {
      margin: 0 auto;
      width: 1220px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .imgBottom {
      margin: 0 auto;
      margin-top: 71px;
      width: 1220px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .serveBoxBig {
      position: relative;
      margin: 0 auto;
      margin-top: 52px;
      box-sizing: border-box;
      width: 1220px;
      // height: 921px;
      padding: 50px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(209, 209, 209, 0.35);
      .top,
      .bottom {
        margin-top: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .topleftImg {
        top: -95px;
        left: -95px;
        z-index: -1;
        position: absolute;
        width: 190px;
        height: 190px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
 <template>
  <div>
    <div class="styletop">
      <!-- 导航开始 -->
      <div class="bread">
        <span class="breadLeft">
          <router-link to="/product">{{
            $t("productList.product")
          }}</router-link> </span
        ><span class="breadCenter">|</span>
        <span class="breadLeft">
          <router-link to="/product/productList">{{
            $t("productList.productList")
          }}</router-link></span
        >
        <span class="breadCenter">|</span>
        <span class="breadRight">{{ uid }}</span>

        <div class="goback" @click="goback">
          <i @click="goback" class="el-icon-back"></i>
        </div>
      </div>
      <!-- 轮播图 -->
      <div class="swiperBox">
        <Swipers v-if="true" :imgArr="imgArr"></Swipers>
      </div>
      <!-- 产品详情参数 -->
      <div class="detailItem">
        <el-row>
          <el-col
            :span="12"
            class="grid-content bg-purple"
            v-for="(item, index) in baseInfo"
            :key="index"
            ><div>{{ item.display }} ：{{ item.value || "--" }}</div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>
 
 <script>
import Swipers from "@/components/swipers.vue";
import { goods_detail, goods_detail_english } from "@/utils/http.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      imgArr: [],
      baseInfo: [],
      uid: "",
    };
  },
  components: {
    Swipers,
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    if (this.language == "en") {
      this.goods_detail_english({ uid: this.$route.query.uid });
    } else {
      this.goods_detail({ uid: this.$route.query.uid });
    }
  },
  methods: {
    async goods_detail(data) {
      const res = await goods_detail(data);
      // this.imgArr = res.img;
      this.imgArr = [...res.img];
      this.baseInfo = res.basis;
      this.publicTitle(res.basis);
    },
    async goods_detail_english(data) {
      const res = await goods_detail_english(data);
      // this.imgArr = res.img;
      this.imgArr = [...res.img];
      this.baseInfo = res.basis;
      this.publicTitle(res.basis);
    },
    // 返回上一级
    goback() {
      // if (window.history.length <= 1) {
      //   this.$router.push({ path: "/" });
      //   return false;
      // } else {
      //   this.$router.go(-1);
      // }
      // this.$router.push({ path: "/product/productList" });
      this.$router.push({ name: "productList" });
    },
    // 提取title 的标题
    publicTitle(data) {
      data.forEach((item) => {
        if (item.key == "uid") {
          this.uid = item.value;
        }
      });
    },
  },

  watch: {
    language(newName, oldName) {
      if (this.language == "en") {
        this.goods_detail_english({ uid: this.$route.query.uid });
      } else {
        this.goods_detail({ uid: this.$route.query.uid });
      }
    },
  },
};
</script>
 <style scoped lang="less">
.styletop {
  width: 100%;
  background: url(~@/assets/images/product/detail/detailbg.png) no-repeat;
  margin: 0px;
  background-size: 100% 100%;
  background-attachment: fixed;

  .bread {
    padding: 0 210px;
    padding-top: 21px;
    position: relative;
    cursor: pointer;
    .breadLeft {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .breadRight {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999;
    }

    .breadCenter {
      font-size: 20px;
      margin: 0 10px;
    }
    .goback {
      top: 50px;
      left: 210px;
      position: absolute;
      .el-icon-back {
        cursor: pointer;
        font-size: 40px;
      }
    }
  }
  // 轮播图
  .swiperBox {
    margin: 0 66px;
    margin-top: 31px;
    position: relative;
  }
  // 产品参数
  .detailItem {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 80px;
    width: 1620px;
    background: #ffffff;
    box-shadow: 0px 6px 49px 3px rgba(197, 209, 232, 0.5);
    border-radius: 20px;
    padding: 40px 50px;
    .grid-content {
      margin-bottom: 20px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
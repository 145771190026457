<template>
  <div class="youshi">
    <div class="topText" v-if="number == '01'">
      <span class="NUmber">{{ number }}</span>
      <span class="text">{{ $t(title) }}</span>
    </div>
    <div class="topText1" v-else-if="number == '02'">
      <span class="NUmber">{{ number }}</span>
      <span class="text">{{ $t(title) }}</span>
    </div>
    <div class="topText1" v-else-if="number == '03'">
      <span class="NUmber">{{ number }}</span>
      <span class="text">{{ $t(title) }}</span>
    </div>
    <div class="topText" v-else-if="number == '04'">
      <span class="NUmber">{{ number }}</span>
      <span class="text">{{ $t(title) }}</span>
    </div>
    <div class="itemImg" v-if="number == '01'">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
    <div class="itemImg2" v-else-if="number == '02'">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
    <div class="itemImg3" v-else-if="number == '03'">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
    <div class="itemImg4" v-else-if="number == '04'">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPYoushi",

  data() {
    return {};
  },
  props: {
    number: {
      type: String,
      default: "01",
    },
    title: {
      type: String,
      default: "多品类一站式的辅料高效供给",
    },
    imgSrc: {
      type: String,
      default: "",
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.youshi {
  .topText {
    width: 690px;
    margin-bottom: 25px;
    .NUmber {
      font-size: 50px;
      font-family: Arial;
      font-weight: bold;
      color: #2e82ff;
      margin-right: 10px;
    }
    .text {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .topText1 {
    width: 500px;
    margin-bottom: 25px;
    .NUmber {
      font-size: 50px;
      font-family: Arial;
      font-weight: bold;
      color: #2e82ff;
      margin-right: 10px;
    }
    .text {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }

  .itemImg {
    width: 690px;
    height: 390px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .itemImg2 {
    width: 500px;
    height: 390px;
    overflow: hidden;
    // background: #e9e9e9;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
  .itemImg3 {
    overflow: hidden;
    width: 500px;
    height: 390px;
    // background: #e9e9e9;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
  .itemImg4 {
    width: 690px;
    height: 390px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.componyImg:hover {
  transform: scale(1.2);
  transition: all 0.5s ease;
}
.componyImg {
  transition: all 0.5s ease;
}
</style>
<template>
  <div class="productList" id="content">
    <div class="bread">
      <span class="breadLeft">
        <router-link to="/product">{{
          $t("productList.product")
        }}</router-link> </span
      ><span class="breadCenter">|</span>
      <span class="breadRight">
        <router-link to="/product/productList">{{
          $t("productList.productList")
        }}</router-link></span
      >
    </div>
    <!-- 添加下拉框 -->
    <div class="tioajainBox">
      <transition enter-active-class="animate__fadeInLeft">
        <div
          class="item"
          @click.stop="
            {
            }
          "
        >
          <popUps
            class="animate__animated animate__fadeInLeft"
            v-if="classCategoryB"
            @publicEvents="publicEvents('品类', $event)"
            @restEvents="restEventsC('品类')"
            :dataId="formData.cate_id"
            :dataList="get_cate1List"
          ></popUps>
          <popUps
            class="animate__animated animate__fadeInLeft"
            v-if="caizhiB"
            @publicEvents="publicEvents('材质', $event)"
            @restEvents="restEventsC('材质')"
            :dataId="formData.material_id"
            :dataList="get_materialList"
          ></popUps>
          <popUps
            class="animate__animated animate__fadeInLeft"
            v-if="xifenB"
            @publicEvents="publicEvents('细分分类', $event)"
            @restEvents="restEventsC('细分分类')"
            :dataId="formData.special_cate"
            :dataList="get_special_cateList"
          ></popUps>
          <popUps
            class="animate__animated animate__fadeInLeft"
            v-if="shiyongB"
            @publicEvents="publicEvents('适用用途', $event)"
            @restEvents="restEventsC('适用用途')"
            :dataId="formData.cate_id2"
            :dataList="get_cate2List"
          ></popUps>
          <popUps
            class="animate__animated animate__fadeInLeft"
            v-if="styleB"
            @publicEvents="publicEvents('风格', $event)"
            @restEvents="restEventsC('风格')"
            :dataList="get_styleList"
            :showrMore="true"
          ></popUps>
        </div>
      </transition>
    </div>
    <div class="productTop">
      <div class="productListLeft">
        <div class="condition">
          <span class="conditionone"
            >{{ $t("productList.screening_conditions") }}：</span
          >
          <span class="conditionItem" @click="CategoryBtn" ref="showPanel"
            >{{ $t("productList.category") }}
            <span v-if="formData.cate_id">(1)</span
            ><img :src="arrowheadBottomC" alt=""
          /></span>
          <span @click="caizhiFBtn" class="conditionItem" ref="caizhiF"
            >{{ $t("productList.material_quality") }}
            <span v-if="formData.material_id">(1)</span
            ><img :src="arrowheadCaizhi" alt=""
          /></span>
          <span
            v-show="language == 'zh'"
            class="conditionItem"
            @click="xifenBtn"
            ref="xifenF"
            >{{ $t("productList.Subdivision_classification") }}
            <span v-if="formData.special_cate">(1)</span>
            <img :src="arrowXifen" alt=""
          /></span>
          <span
            v-show="language == 'zh'"
            class="conditionItem"
            @click="ApplicableBtn"
            ref="Applicable"
            >{{ $t("productList.Applicable_use") }}
            <span v-if="formData.cate_id2">(1)</span>
            <img :src="arrowApplicable" alt=""
          /></span>
          <span class="conditionItem" @click="styleBtn" ref="styles"
            >{{ $t("productList.style") }}
            <span v-if="formData.style">({{ styleLength }})</span>
            <img :src="arrowstyle" alt=""
          /></span>
        </div>
        <div class="conditionOther" v-if="formDataArr.length > 0">
          <div
            class="conditionOtherItem"
            v-for="(item, index) in formDataArr"
            :key="index"
          >
            <span>{{ language == "zh" ? item.title : item.english }}</span>
            <img
              @click="deleOption(item.type, item)"
              style="width: 12px; height: 12px"
              src="@/assets/images/product/error.png"
              alt=""
            />
          </div>

          <div class="conditionOtherRest" @click="bntAllFormData">
            {{ $t("productList.Reset_all_screens") }}
          </div>
        </div>
      </div>
      <div class="productListRight" v-if="!showSearch">
        <div class="imgBox" v-if="!this.showImg">
          <el-upload
            :action="picUploadPositionTld"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img src="@/assets/images/product/camera.png" alt="" />
            <span>{{ $t("productList.Figure_search_search") }}</span>
          </el-upload>
        </div>
        <div class="haveImg" v-else>
          <div class="haveImgLeft">
            <div class="haveImgitem" @click="toHandleOpen(formData.img_arr)">
              {{ $t("productList.Re_box_selection") }}
            </div>
            <div class="haveImgitemH">
              <el-upload
                :action="picUploadPositionTld"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                {{ $t("productList.Change_a") }}
              </el-upload>
            </div>
            <div class="haveImgitemH" @click="CancelSearch">
              {{ $t("productList.Cancel_the_map_search") }}
            </div>
          </div>
          <div class="haveImgRight">
            <div
              class="closeImg"
              @click="CancelSearch"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
            >
              <img :src="errorGray" alt="" />
            </div>
            <img class="auto-img" :src="showImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="maskBox" class="mask"></div>
    <!-- <div class="listBox mask"> -->
    <div v-if="goods_list_data.length > 0" class="listBox">
      <div class="listBoxItem" v-for="item in goods_list_data" :key="item.id">
        <div class="itemImg" @click="gotuDetail(item.uid)">
          <!-- <img
            class="auto-img"
            :src="item.thumb_display ? item.thumb_display : imgsrc"
            alt=""
          /> -->
          <img
            class="auto-img"
            v-lazy="item.thumb_display ? item.thumb_display : imgsrc"
            alt=""
          />
        </div>
        <div class="uid">{{ item.uid }}</div>
        <div class="title">
          {{ language == "zh" ? item.title : item.title_english || "--" }}
        </div>
      </div>
    </div>
    <div class="noDate" v-else>
      <img src="@/assets/images/product/noData.png" alt="" />
    </div>

    <el-pagination
      v-if="goods_list_data.length > 0"
      class="pagination"
      @current-change="handleCurrentChange"
      :page-size="page_number"
      layout="total, prev, pager, next, jumper"
      :total="pageTotal"
      :current-page="page"
    >
    </el-pagination>

    <!-- 剪辑图片的弹窗 -->
    <ImgCutter
      ref="imageCut"
      @cutDown="cutDown"
      :boxWidth="500"
      :boxHeight="500"
      :imgx="imgObj.x"
      :imgy="imgObj.y"
      :cutWidth="imgObj.cutWidth"
      :cutHeight="imgObj.cutHeight"
      :name="imgObj.name"
      :path="imgObj.path"
    >
      <span slot="open"></span>
    </ImgCutter>
  </div>
</template>

<script>
import arrowheadTop from "@/assets/images/product/arrowheadTop.png";
import arrowheadBottom from "@/assets/images/product/arrowheadBottom.png";
import errorGray from "@/assets/images/product/errorGray.png";
import errorRed from "@/assets/images/product/errorRed.png";
import popUps from "./components/popUp";
import imgsrc from "@/assets/images/product/noImg.png";
import {
  get_cate1,
  get_material,
  get_special_cate,
  get_cate2,
  get_style,
  goods_list,
} from "@/utils/http.js";
import ImgCutter from "@/components/ImgCutter";
import { picUploadPositionTld } from "@/api/img";
import { mapState } from "vuex";
export default {
  name: "CompanynetworkPProuductList",
  components: {
    popUps,
    ImgCutter,
  },
  data() {
    return {
      imgObj: {
        name: "1.jpg",
        src: "",
        width: 0,
        height: 0,
        cutWidth: 0,
        cutHeight: 0,
        x: 0,
        y: 0,
      },
      formData: {
        cate_id: "",
        material_id: "",
        special_cate: "",
        cate_id2: "",
        style: "",
        img_arr: "",
      },
      showImg: "",
      picUploadPositionTld: picUploadPositionTld, // 上传图片地址
      page: 10,
      page_number: 24,
      pageTotal: 0,
      arrowheadTop: arrowheadTop, // 向上图标
      arrowheadBottom: arrowheadBottom, // 向上图标
      arrowheadBottomC: arrowheadBottom, // 品类
      arrowheadCaizhi: arrowheadBottom, // 材质
      arrowXifen: arrowheadBottom, // 细分分类
      arrowApplicable: arrowheadBottom, // 适用用途
      arrowstyle: arrowheadBottom, // 风格
      errorGray: errorGray, // 错误图标
      goods_list_data: [], // 获取列表数据
      get_cate1List: [], // 品类数据
      get_materialList: [], //材质数据
      get_special_cateList: [], //细分分类
      get_cate2List: [], //适用途径
      get_styleList: [], // 风格列表
      imgsrc: imgsrc, // 暂无图片
      classCategoryB: false, // 控制品类的下拉框
      caizhiB: false, // 控制品类的下拉框
      xifenB: false, // 控制品类的下拉框
      shiyongB: false, // 控制品类的下拉框
      styleB: false, // 控制品类的下拉框
      maskBox: false, // 控制遮罩层
      styleLength: 0, // 风格筛选
      formDataArr: [], // 下面筛选条件
    };
  },
  async activated() {
    this.formData = {
      cate_id: "",
      material_id: "",
      special_cate: "",
      cate_id2: "",
      style: "",
      img_arr: "",
    };
    let item = this.$route.query.item && JSON.parse(this.$route.query.item);
    if (item) {
      let type = item.type;
      if (type == "品类") {
        this.formData.cate_id = item.id;
      } else if (type == "材质") {
        this.formData.cate_id = item.cate_id;
        this.formData.material_id = item.id;
      } else if (type == "细分分类") {
        this.formData.cate_id = item.cate_id;
        this.formData.material_id = item.material_id;
        this.formData.special_cate = item.id;
      } else if (type == "适用用途") {
        this.formData.cate_id = item.pid;
        this.formData.cate_id2 = item.id;
      } else if (type == "风格") {
        this.get_style2(item.id);
        this.formData.style = item.id;
        this.styleLength = 1;
      }
      await this.get_cate1();
      await this.get_material(this.formData.cate_id);
      await this.get_special_cate(
        this.formData.cate_id,
        this.formData.material_id
      );
      await this.get_cate2(this.formData.cate_id);
    } else {
      this.get_cate1();
      this.get_material();
      this.get_special_cate();
      this.get_cate2();
      this.get_style();
    }
    this.goods_list();
    this.arrangeData();
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.$refs && this.$refs.showPanel) {
        let isSelf = this.$refs.showPanel.contains(e.target);
        if (!isSelf) {
          this.classCategoryB = false;
          if (
            this.classCategoryB ||
            this.caizhiB ||
            this.xifenB ||
            this.shiyongB ||
            this.styleB
          ) {
          } else {
            this.maskBox = false;
          }
          this.arrowheadBottomC = arrowheadBottom;
        }
      }
      if (this.$refs && this.$refs.caizhiF) {
        let isSelf = this.$refs.caizhiF.contains(e.target);
        if (!isSelf) {
          this.caizhiB = false;
          if (
            this.classCategoryB ||
            this.caizhiB ||
            this.xifenB ||
            this.shiyongB ||
            this.styleB
          ) {
          } else {
            this.maskBox = false;
          }
          this.arrowheadCaizhi = arrowheadBottom;
        }
      }
      //     细分分类
      if (this.$refs && this.$refs.xifenF) {
        let isSelf = this.$refs.xifenF.contains(e.target);
        if (!isSelf) {
          this.xifenB = false;
          if (
            this.classCategoryB ||
            this.caizhiB ||
            this.xifenB ||
            this.shiyongB ||
            this.styleB
          ) {
          } else {
            this.maskBox = false;
          }
          this.arrowXifen = arrowheadBottom;
        }
      }
      //     适用用途
      if (this.$refs && this.$refs.Applicable) {
        let isSelf = this.$refs.Applicable.contains(e.target);
        if (!isSelf) {
          this.shiyongB = false;
          if (
            this.classCategoryB ||
            this.caizhiB ||
            this.xifenB ||
            this.shiyongB ||
            this.styleB
          ) {
          } else {
            this.maskBox = false;
          }
          this.arrowApplicable = arrowheadBottom;
        }
      }
      if (this.$refs && this.$refs.styles) {
        let isSelf = this.$refs.styles.contains(e.target);
        if (!isSelf) {
          this.styleB = false;
          if (
            this.classCategoryB ||
            this.caizhiB ||
            this.xifenB ||
            this.shiyongB ||
            this.styleB
          ) {
          } else {
            this.maskBox = false;
          }
          this.arrowstyle = arrowheadBottom;
        }
      }
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取品类
    async get_cate1() {
      const res = await get_cate1();
      res.list.forEach((item) => {
        item.type = "品类";
      });
      this.get_cate1List = res.list;
    },
    // 获取材质
    async get_material(cate_id) {
      const res = await get_material({ cate_id });
      res.list.forEach((item) => {
        item.type = "材质";
      });
      this.get_materialList = res.list;
    },
    //细分分类
    async get_special_cate(cate_id, material_id) {
      const res = await get_special_cate({ cate_id, material_id });
      this.get_special_cateList = res.list;
      res.list.forEach((item) => {
        item.type = "细分分类";
      });
    },
    // 适用途径
    async get_cate2(cate_id) {
      const res = await get_cate2({ cate_id });
      this.get_cate2List = res.list;
      res.list.forEach((item) => {
        item.type = "适用用途";
      });
    },
    // 风格
    async get_style(cate_id) {
      const res = await get_style({ cate_id });
      res.list.forEach((item) => {
        item.show = false;
        item.type = "风格";
      });
      this.get_styleList = res.list;
    },
    // 风格2
    async get_style2(id) {
      const res = await get_style();
      res.list.forEach((item) => {
        if (item.id == id) {
          item.show = true;
        } else {
          item.show = false;
        }
      });
      this.get_styleList = res.list;
    },
    // 获取数据列表
    async goods_list() {
      const res = await goods_list({
        page: this.page,
        page_number: this.page_number,
        ...this.formData,
      });
      this.goods_list_data = res.list;
      this.pageTotal = res.count;
    },
    // 点击品类显示下拉框
    CategoryBtn() {
      this.get_cate1();
      this.classCategoryB = !this.classCategoryB;
      if (this.classCategoryB) {
        this.maskBox = true;
        this.arrowheadBottomC = arrowheadTop;
      } else {
        this.arrowheadBottomC = arrowheadBottom;
        this.maskBox = false;
      }
    },
    // 点击材质显示下拉框
    caizhiFBtn() {
      this.caizhiB = !this.caizhiB;
      if (this.caizhiB) {
        this.arrowheadCaizhi = arrowheadTop;
        this.maskBox = true;
      } else {
        this.arrowheadCaizhi = arrowheadBottom;
        this.maskBox = false;
      }
      if (this.formData.cate_id) {
        this.get_material(this.formData.cate_id);
      } else {
        this.get_material();
      }
    },
    // 点击细分分类显示下拉框
    xifenBtn() {
      this.xifenB = !this.xifenB;
      if (this.xifenB) {
        this.arrowXifen = arrowheadTop;
        this.maskBox = true;
      } else {
        this.arrowXifen = arrowheadBottom;
        this.maskBox = false;
      }
      if (this.formData.cate_id && this.formData.material_id) {
        this.get_special_cate(this.formData.cate_id, this.formData.material_id);
      } else if (this.formData.cate_id) {
        this.get_special_cate(this.formData.cate_id);
      } else if (this.formData.material_id) {
        this.get_special_cate("", this.formData.material_id);
      } else {
        this.get_special_cate();
      }
    },
    // 点击适用用途
    ApplicableBtn() {
      this.shiyongB = !this.shiyongB;
      if (this.shiyongB) {
        this.arrowApplicable = arrowheadTop;
        this.maskBox = true;
      } else {
        this.arrowApplicable = arrowheadBottom;
        this.maskBox = false;
      }
      if (this.formData.cate_id) {
        this.get_cate2(this.formData.cate_id, this.formData.material_id);
      } else {
        this.get_cate2();
      }
    },
    // 点击风格
    styleBtn() {
      this.styleB = !this.styleB;
      if (this.styleB) {
        this.arrowstyle = arrowheadTop;
        this.maskBox = true;
      } else {
        this.arrowstyle = arrowheadBottom;
        this.maskBox = false;
      }
      if (this.formData.cate_id) {
        this.get_style(this.formData.cate_id);
      } else {
        // this.get_style();
      }
    },
    // 单选框下面的每一项
    publicEvents(type, item) {
      if (type == "品类") {
        this.formData.cate_id = item ? item.id : "";
      } else if (type == "材质") {
        if (item) this.formData.cate_id = item.cate_id;
        this.formData.material_id = item ? item.id : "";
        //如果有品类的id 的话就更新子现有的材质
        if (this.formData.cate_id) {
          this.get_material(this.formData.cate_id);
        } else {
          this.get_material();
        }
      } else if (type == "细分分类") {
        if (item) this.formData.cate_id = item.cate_id;
        if (item) this.formData.material_id = item.material_id;
        this.formData.special_cate = item ? item.id : "";
        //  如果有品类id 和材质id 各种情况
        if (this.formData.cate_id && this.formData.material_id) {
          this.get_special_cate(
            this.formData.cate_id,
            this.formData.material_id
          );
        } else if (this.formData.cate_id) {
          this.get_special_cate(this.formData.cate_id);
        } else if (this.formData.material_id) {
          this.get_special_cate("", this.formData.material_id);
        } else {
          this.get_special_cate();
        }
      } else if (type == "适用用途") {
        if (item) this.formData.cate_id = item.pid;
        this.formData.cate_id2 = item ? item.id : "";
        if (this.formData.cate_id) {
          this.get_cate2(this.formData.cate_id, this.formData.material_id);
        } else {
          this.get_cate2();
        }
      } else if (type == "风格") {
        let ArrayStr = String(this.formData.style);
        var sum = ArrayStr.indexOf(item.id);
        // //若包含返回大于等于0的整数值，若不包含"J"则返回"-1。
        if (sum > -1) {
          let ArrayStr = String(this.formData.style);
          let ArrayStyle = ArrayStr.split(",");
          let delindex = ArrayStyle.indexOf(String(item.id));
          this.get_styleList[delindex].show = false;
          ArrayStyle.splice(delindex, 1);
          this.styleLength = ArrayStyle.length;
          this.formData.style = ArrayStyle.join(",");
        } else {
          // 修改风格里面的数
          let index = this.get_styleList.findIndex(
            (list) => list.id === item.id
          );
          this.get_styleList[index].show = true;
          if (this.formData.style) {
            this.formData.style = this.formData.style + "," + item.id;
          } else {
            this.formData.style = item.id;
          }
          // 添加数据
          item.type = type;
          let ArrayStr = String(this.formData.style);
          let ArrayStyle = ArrayStr.split(",");
          this.styleLength = ArrayStyle.length;
          if (ArrayStyle.length == 0) {
            this.formData.style = "";
          } else {
          }
        }
      }
      this.page = 1;
      this.goods_list();
      this.arrangeData();
    },
    // 点击重置
    restEventsC(type) {
      if (type == "品类") {
        this.formData.cate_id = "";
        this.formData.material_id = "";
        this.formData.special_cate = "";
      } else if (type == "材质") {
        this.formData.material_id = "";
        this.formData.special_cate = "";
      } else if (type == "细分分类") {
        this.formData.special_cate = "";
      } else if (type == "适用用途") {
        this.formData.cate_id2 = "";
      } else if (type == "风格") {
        this.formData.style = "";
        this.get_styleList.forEach((item) => (item.show = false));
      }
      this.arrangeData();
      this.goods_list();
    },

    //删除筛选项
    deleOption(type, item) {
      if (type == "品类") {
        this.formData.cate_id = "";
      } else if (type == "材质") {
        this.formData.material_id = "";
        // this.get_materialItem = [];
      } else if (type == "细分分类") {
        this.formData.special_cate = "";
        // this.get_special_cateItem = [];
      } else if (type == "适用用途") {
        this.formData.cate_id2 = "";
        // this.get_cate2Item = [];
      } else if (type == "风格") {
        let id = String(item.id);
        let arr = this.formData.style.split(",");
        if (arr.indexOf(id) > -1) {
          arr.splice(arr.indexOf(id), 1);
        }
        this.styleLength = arr.length;
        this.formData.style = arr.join(",");
        // 删除数组里面对应的值
      }
      this.arrangeData();
      this.page = 1;
      this.goods_list();
    },
    // 删除所有选项
    bntAllFormData() {
      this.formData = {
        cate_id: "",
        material_id: "",
        special_cate: "",
        cate_id2: "",
        style: "",
        img_arr: "",
      };
      this.arrangeData();
      this.page = 1;
      this.goods_list();
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      // content.scrollIntoView();
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 30);

      this.goods_list();
    },
    // 上传图片前的验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/png" || file.type === "image/jpeg";
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt4M) {
        this.$message.error("上传图片大小不能超过 4MB!");
      }
      if (isJPG && isLt4M) this.loading = true;
      return isJPG && isLt4M;
    },
    // 上传图片成功
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        // if (this.showImg && this.formData.img_arr) {
        //   image_delTld({ url: this.showImg });
        // }
        this.showImg = res.data.pic;
        this.formData.img_arr = JSON.stringify(res.data);
        this.page = 1;
        this.goods_list();
      }
    },
    CancelSearch() {
      this.showImg = "";
      this.formData.img_arr = "";
      this.page = 1;
      this.goods_list();
    },
    mouseOver() {
      this.errorGray = errorRed;
    },
    mouseLeave() {
      this.errorGray = errorGray;
    },
    // 调用imgCut子组件方法并将图片传入---打开弹框
    toHandleOpen(dataStr) {
      let data = JSON.parse(dataStr);
      this.imgObj.src = data.pic;
      this.imgObj.x = +data.x;
      this.imgObj.y = +data.y;
      this.imgObj.cutWidth = +data.width;
      this.imgObj.cutHeight = +data.height;
      this.imgObj.type = data.type;
      this.imgObj.path = data.path || "";
      let img = new Image();
      img.src = data.pic;
      img.onload = () => {
        this.imgObj.width = img.width;
        this.imgObj.height = img.height;
        this.$refs.imageCut.handleOpen(this.imgObj);
      };
    },
    // 图片裁剪
    cutDown(data) {
      let obj = {};
      // 传给后台的width和height----距离图片的距离x和y
      if (data.data.imgswidth <= 300 && data.data.imgsheight <= 300) {
        obj.width = data.data.width;
        obj.height = data.data.height;
        obj.x = data.data.imgx;
        obj.y = data.data.imgy;
      } else {
        obj.width = parseInt(
          (data.data.width / data.data.imgwidth) * data.data.imgswidth
        );
        obj.height = parseInt(
          (data.data.height / data.data.imgheight) * data.data.imgsheight
        );
        obj.x = parseInt(
          (data.data.imgx / data.data.imgwidth) * data.data.imgswidth
        );
        obj.y = parseInt(
          (data.data.imgy / data.data.imgheight) * data.data.imgsheight
        );
      }
      let isxFalse = data.data.imgx + data.data.width > data.data.imgwidth;
      let isyFalse = data.data.imgy + data.data.height > data.data.imgheight;
      if (data.data.imgx < 0 || data.data.imgy < 0 || isxFalse || isyFalse) {
        this.$message({
          message: "超出图片的可截取范围，请重新截图！",
          type: "error",
          duration: 3000,
        });
        return;
      }
      // console.log("data.data.imgx:", data.data.imgx, "data.data.imgy:", data.data.imgy, "isxFalse:", isxFalse, "isyFalse:", isyFalse)
      obj.pic = data.data.pic;
      obj.type = data.data.type;
      obj.path = data.data.path;
      this.formData.img_arr = JSON.stringify(obj);
      this.$refs.imageCut.handleClose();
      this.$forceUpdate(); // 强制刷新组件
      //   请求列表
      this.page = 1;
      this.goods_list();
    },
    // 去详情页面
    gotuDetail(uid) {
      this.$router.push({ name: "ProductDetail", query: { uid: uid } });
    },
    // 关于整理筛选条件的方法
    arrangeData() {
      for (let key in this.formData) {
        if (key == "cate_id") {
          var cate_iditem = this.get_cate1List.filter(
            (item) => item.id == this.formData[key]
          );
        }
        if (key == "material_id") {
          var material_iditem = this.get_materialList.filter(
            (item) => item.id == this.formData[key]
          );
        }
        if (key == "special_cate") {
          var special_cateitem = this.get_special_cateList.filter(
            (item) => item.id == this.formData[key]
          );
        }
        if (key == "cate_id2") {
          var cate_id2item = this.get_cate2List.filter(
            (item) => item.id == this.formData[key]
          );
        }
        if (key == "style") {
          let Strin = String(this.formData[key]);
          let arr = Strin.split(",");
          var styleitem = [];
          arr.forEach((item1) => {
            styleitem.push(
              ...this.get_styleList.filter((item) => item.id == item1)
            );
          });
        }
      }
      this.formDataArr = [
        ...cate_iditem,
        ...material_iditem,
        ...special_cateitem,
        ...cate_id2item,
        ...styleitem,
      ];
    },
    //   滚动屏幕关闭弹窗
    handleScroll() {
      // console.log(565656);
      this.classCategoryB = false; // 控制品类的下拉框
      this.caizhiB = false; // 控制品类的下拉框
      this.xifenB = false; // 控制品类的下拉框
      this.shiyongB = false; // 控制品类的下拉框
      this.styleB = false; // 控制品类的下拉框
      this.maskBox = false; // 控制遮罩层
    },
  },
  computed: {
    ...mapState(["language"]),
    // 计算属性的 getter
    showSearch: function () {
      if (
        this.styleB ||
        this.shiyongB ||
        this.xifenB ||
        this.caizhiB ||
        this.classCategoryB
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.productList {
  border-top: 1px solid #eaeaea;
  .bread {
    padding: 0 210px;
    cursor: pointer;
    margin-top: 20px;
    .breadLeft {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .breadRight {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #a3a3a3;
    }

    .breadCenter {
      font-size: 20px;
      margin: 0 10px;
    }
  }
  .tioajainBox {
    padding: 0 210px;
    position: relative;
    .item {
      position: absolute;
      top: 70px;
      left: 0;
      width: calc(100% - 0px);
      background-color: #fff;
      z-index: 8888;
      .container {
        margin-top: 35px;
        margin-bottom: 35px;
        padding-left: 325px;
        padding-right: 210px;
        .radio {
          display: inline-block;
          color: #333333;
          margin-right: 28px;
          margin-bottom: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        .active {
          color: #2e82ff;
        }
        .resBtnItme {
          cursor: pointer;
          margin-left: 221px;
          border-bottom: 2px solid #000;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #1d1d1d;
        }
      }
    }
  }

  .productTop {
    padding: 0 210px;
    display: flex;
    justify-content: space-between;
    .productListLeft {
      max-width: 1300px;
      .condition {
        position: relative;
        margin-top: 35px;
        margin-bottom: 35px;
        .conditionone {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #9f9f9f;
        }
        .conditionItem {
          cursor: pointer;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin: 0px 20px;
        }
      }
      .conditionOther {
        height: 36px;
        .conditionOtherItem {
          margin-bottom: 20px;
          cursor: pointer;
          display: inline-block;
          text-align: center;
          line-height: 36px;
          background: #1d1d1d;
          border-radius: 2px;
          padding: 0 30px;
          color: #fff;
          margin-right: 10px;
          span {
            margin-right: 9px;
          }
        }
        .conditionOtherRest {
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #1d1d1d;
          border-bottom: 2px solid #000;
          padding-bottom: 3px;
          margin-left: 26px;
        }
      }
    }
    .productListRight {
      margin-right: 20px;
      display: flex;
      align-items: center;
      .imgBox {
        cursor: pointer;
        height: 18px;
        img {
          width: 24px;
          height: 18px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .haveImg {
        display: flex;
        .haveImgLeft {
          cursor: pointer;
          .haveImgitem {
            // width: 92px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: #2e82ff;
            border-radius: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 12px;
            padding: 0 10px;
          }
          .haveImgitemH {
            // width: 92px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            border: 1px solid #2e82ff;
            border-radius: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #2e82ff;
            margin-bottom: 12px;
            padding: 0 10px;
          }
        }
        .haveImgRight {
          position: relative;
          margin-left: 20px;
          height: 136px;
          width: 136px;
          border: 1px solid #ccc;
          .auto-img {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
          }
          .closeImg {
            cursor: pointer;
            top: 0px;
            right: 0px;
            position: absolute;
            z-index: 55;
          }
        }
      }
    }
  }

  .listBox {
    padding: 0 210px;
    padding-top: 20px;
    margin-top: 22px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .listBoxItem {
      width: 354px;
      margin-right: 19px;
      .itemImg {
        width: 354px;
        height: 354px;
        border: 1px solid #ccc;
        .auto-img {
          position: relative;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
      }
      .uid {
        margin-top: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1d1d1d;
      }
      .title {
        width: 354px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1d1d1d;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
  .noDate {
    display: flex;
    justify-content: center;
    margin-top: 155px;
  }
  .pagination {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 97px;
  }
}
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  position: fixed;
  top: 360px;
  left: 0;
  width: 100%;
  //   height: 100%
  height: 100%;
  z-index: 555;
}
</style>
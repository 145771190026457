<template>
  <div class="imgbox">
    <img src="@/assets/images/superiority/superioritytop.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPImg",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.imgbox {
  width: 100%;
  height: 390px;
  background-color: #eae8eb;
  img {
    width: 100%;
    height: 100%;
  }
  // background: url(~@/assets/images/superiority/superioritytop.png) no-repeat;
}
</style>
// / 导入封装好的axios实例
import request from "./request";

// 请求品类
export function get_cate1(data) {
  return request({
    url: "/index/goods/get_cate1",
    method: "post",
    data: data,
  });
}
// 获取材质
export function get_material(data) {
  return request({
    url: "/index/goods/get_material",
    method: "post",
    data: data,
  });
}
// 获取细分分类功能
export function get_special_cate(data) {
  return request({
    url: "/index/goods/get_special_cate",
    method: "post",
    data: data,
  });
}
// 获取适用范围
export function get_cate2(data) {
  return request({
    url: "/index/goods/get_cate2",
    method: "post",
    data: data,
  });
}
// 获取风格
export function get_style(data) {
  return request({
    url: "/index/goods/get_style",
    method: "post",
    data: data,
  });
}
//  产品列表
export function goods_list(data) {
  return request({
    url: "/index/goods/goods_list",
    method: "post",
    data: data,
  });
}
//产品详情
export function goods_detail(data) {
  return request({
    url: "/index/goods/goods_detail",
    method: "post",
    data: data,
  });
}
export function goods_detail_english(data) {
  return request({
    url: "/index/goods/goods_detail_english",
    method: "post",
    data: data,
  });
}

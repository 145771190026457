<template>
  <div class="serveBox" v-if="!showChang">
    <div class="top">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
    <div class="bottom">{{ $t(title) }}</div>
  </div>
  <div class="serveBox1" v-else>
    <div class="top">
      <img class="componyImg" :src="imgSrc" alt="" />
    </div>
    <div class="bottom">{{ $t(title) }}</div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPServeitem",

  data() {
    return {};
  },
  props: {
    showChang: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.serveBox {
  width: 350px;
  .top {
    height: 350px;
    background: #e9e9e9;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
  .bottom {
    margin-top: 19px;
    text-align: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.serveBox1 {
  width: 542px;
  .top {
    width: 100%;
    height: 350px;
    background: #e9e9e9;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    margin-top: 19px;
    text-align: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.componyImg:hover {
  transform: scale(1.2);
  transition: all 0.5s ease;
}
.componyImg {
  transition: all 0.5s ease;
}
</style>
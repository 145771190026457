<template>
  <div class="contact">
    <div class="topimg">
      <img src="@/assets/images/contact/ourtop.png" alt="" />
    </div>

    <div class="textBox">
      <Lines titleText="contact.contact_us"></Lines>
    </div>
    <!-- 联系我们 -->
    <div class="itemClass">
      <div
        class="itemClassItem"
        data-aos="zoom-in"
        data-aos-offset="200"
        :data-aos-once="true"
      >
        <img src="@/assets/images/contact/phone.png" alt="" />
        <div class="itemClassRight">
          <div class="RightTop">{{ $t("contact.contact_number") }}</div>
          <div class="RightBottom">0573-84562222</div>
          <div class="RightBottom">+86-19817195807</div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-offset="200"
        :data-aos-once="true"
        class="itemClassItem"
      >
        <img src="@/assets/images/contact/mailbox.png" alt="" />
        <div class="itemClassRight">
          <div class="RightTop">{{ $t("contact.mail_box") }}</div>
          <div class="RightBottom">ling@td-button.com.cn</div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-offset="200"
        :data-aos-once="true"
        class="itemClassItem"
      >
        <img src="@/assets/images/contact/factory.png" alt="" />
        <div class="itemClassRight">
          <div class="RightTop">{{ $t("contact.Factory_address") }}</div>
          <div class="RightBottom">
            {{ $t("contact.Factory_address1") }}
          </div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-offset="200"
        :data-aos-once="true"
        class="itemClassItem"
      >
        <img src="@/assets/images/contact/company.png" alt="" />
        <div class="itemClassRight">
          <div class="RightTop">{{ $t("contact.Company_address") }}</div>
          <div class="RightBottom">
            {{ $t("contact.Company_address1") }}
          </div>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-offset="200"
        :data-aos-once="true"
        class="itemClassItem"
      >
        <img src="@/assets/images/contact/weixin.png" alt="" />
        <div class="itemClassRight">
          <div class="RightTop">{{ $t("contact.Wechat") }}</div>
          <div class="RightBottom">tldyewu008</div>
        </div>
        <div class="code" @click="showCode">
          <img
            style="width: 60%; height: 60%"
            src="@/assets/images/contact/code.jpg"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 地图 -->
    <div class="mapBox">
      <Map></Map>
    </div>
    <el-dialog
      title="微信二维码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="text-align: center">
        <img
          style="width: 60%; height: 60%"
          src="@/assets/images/contact/code.jpg"
          alt=""
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Lines from "@/components/line.vue";
import Map from "./components/map.vue";
export default {
  name: "CompanynetworkPIndex",
  components: {
    Lines,
    Map,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },

  mounted() {},
  created() {
    // this.getClickInfo();
  },
  methods: {
    //地图加载后的回调
    showCode() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.contact {
  .topimg {
    width: 100%;
    height: 390px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textBox {
    margin-top: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      font-size: 46px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
    }
    .img {
      margin-top: 27px;
    }
  }
  .itemClass {
    margin: 55px 150px 73px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    .itemClassItem {
      box-sizing: border-box;
      // width: 268px;
      width: 300px;
      height: 142px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(209, 209, 209, 0.35);
      border-radius: 10px;
      display: flex;
      // justify-content: start;
      align-items: center;
      padding: 0 22px;
      transition: all 0.5s ease;
      &:hover {
        transform: scale(1.1);
        transition: all 1s ease;
      }
      img {
        width: 54px;
        height: 54px;
        margin-right: 15px;
      }
      .itemClassRight {
        .RightTop {
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-bottom: 18px;
        }
        .RightBottom {
          font-size: 14px;
          line-height: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
      }
      .code {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .mapBox {
    width: 1100px;
    height: 522px;
    margin: 0 auto;
    margin-bottom: 181px;
    .map {
      margin-top: 20px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
import { render, staticRenderFns } from "./serveitem.vue?vue&type=template&id=a33d9aee&scoped=true&"
import script from "./serveitem.vue?vue&type=script&lang=js&"
export * from "./serveitem.vue?vue&type=script&lang=js&"
import style0 from "./serveitem.vue?vue&type=style&index=0&id=a33d9aee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a33d9aee",
  null
  
)

export default component.exports
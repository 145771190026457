<template>
  <div class="textImg" :class="{ toleft: toleft }">
    <div
      class="textImgLeft"
      data-aos="fade-down"
      data-aos-offset="200"
      :data-aos-once="true"
    >
      565656
    </div>
    <div class="textImgRight">
      <div class="text">文字标题</div>
      <div class="line"></div>
      <div
        class="rightText"
        data-aos="fade-down"
        data-aos-offset="200"
        :data-aos-once="true"
      >
        <p>
          {{ title1 }}
        </p>
        <p>
          {{ title2 }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanynetworkPLeftRight",

  data() {
    return {};
  },
  props: {
    toleft: {
      type: Boolean,
      default: false,
    },
    title1: {
      type: String,
      default: "暂无数据",
    },
    title2: {
      type: String,
      default: "暂无数据",
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.textImg {
  margin: 0 auto;
  margin-top: 100px;
  height: 381px;
  width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .textImgLeft {
    width: 540px;
    height: 381px;
    background-color: #e6e6e6;
  }
  .textImgRight {
    width: 542px;
    height: 381px;
    .text {
      margin-top: 71px;
      margin-bottom: 15px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
    .line {
      width: 50px;
      height: 2px;
      background: #2e82ff;
      border-radius: 1px;
      margin-bottom: 16px;
    }
    .rightText {
      p {
        margin-top: 32px;
        line-height: 28px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.toleft {
  flex-direction: row-reverse;
}
</style>
import { render, staticRenderFns } from "./prouductList.vue?vue&type=template&id=2658f638&scoped=true&"
import script from "./prouductList.vue?vue&type=script&lang=js&"
export * from "./prouductList.vue?vue&type=script&lang=js&"
import style0 from "./prouductList.vue?vue&type=style&index=0&id=2658f638&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2658f638",
  null
  
)

export default component.exports
<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in imgArr" :key="index">
        <img :src="item.pic" alt="" />
      </div>
      <!-- <div class="swiper-slide">
        <img
          src="https://iscm.cnfzflw.com/images/tld/remove/20220627/16563112479030_thumb.jpg"
          alt=""
        />
      </div>
      <div class="swiper-slide">
        <img
          src="https://iscm.cnfzflw.com/images/tld/remove/20220627/16563112479030_thumb.jpg"
          alt=""
        />
      </div>
      <div class="swiper-slide">
        <img
          src="https://iscm.cnfzflw.com/images/tld/remove/20220627/16563112479030_thumb.jpg"
          alt=""
        />
      </div> -->
    </div>
    <!-- Add Pagination -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-pagination"></div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      swiper: "",
    };
  },
  props: {
    imgArr: {
      type: Array,
    },
  },
  created() {},
  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      autoplay: true, //可选选项，自动滑动
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  methods: {},
  created() {},
  updated() {
    //实例更新完成
    this.swiper.update();
  },
};
</script>
<style scoped lang="less" >
.swiper-container {
  width: 100%;
  /* width: 380px; */
  height: 475px;
  /* width: 100%;
  height: 200px; */
}
.swiper-slide {
  height: 475px;
  text-align: center;
  // background: red;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scalex(0.8);
  transform: scaley(0.8);
  transform-origin: bottom;
  img {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
  color: #b5b5b7;
  color: #b5b5b7;
  margin-top: 82px;
  display: block;
  width: 64px;
  background-color: #f2f2f4;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
  color: #b5b5b7;
  margin-top: 82px;
  display: block;
  width: 64px;
  background-color: #f2f2f4;
}
</style>